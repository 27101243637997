import ContentRetriever from 'vg-content-retriever';
import EnvConfigService from "@/js/services/envConfig.service";
import logger from "@/js/services/logger.service";
let ENDPOINTS = require('../../config/endpoints.constant');

let navData = {};

navData.getUrl = () => {
    let url = "";
    const domain = window.location.hostname;

    const aemNonProdDomains = [
        "https://fasaemsitesauth.fase.c1.vanguard.com",
        "https://fasaemsitesauth.fast.c1.vanguard.com"
    ];

    // If in AEM non prod preview or AEM local development, use AEM direct non prod endpoint
    if (aemNonProdDomains.includes(domain) || domain.includes("coder.opst.c1.vanguard.com")) {
        url = "https://fasaemdisp.fast.c1.vanguard.com/content/fas/digital/globalheader/public.caas.json";
        // Else if in prod preview use AEM direct prod endpoint
    } else if (domain.includes("https://fasaemsitesauth.fasp.c1.vanguard.com")) {
        url = "https://fasaemdisp.fasp.c1.vanguard.com/content/fas/digital/globalheader/public.caas.json";
        // Else use node server endpoint
    } else if(domain.includes("localhost")) {
        url = ENDPOINTS.GLOBAL_HEADER_CONTENT;
    } else {
        url = EnvConfigService.buildWebappRegionURL() + ENDPOINTS.GLOBAL_HEADER_CONTENT;
    }
    return url;
}

navData.get = async () => {
    const contentUrl = navData.getUrl();

    try {
        const response = await ContentRetriever.get(contentUrl);
        let navContent = response;
        navContent.siloscontent.silos = Object.values(navContent.siloscontent.silos)
        navContent.siloscontent.silos.forEach(silo => {
            if (silo.columns) {
                if (!Array.isArray(silo.columns)) {
                    silo.columns = Object.values(silo.columns);
                }
                silo.columns.forEach(column => {
                    let section,sectionData;
                    if(column.sectionsName === 'advisorsAlphaCol3Sections' || column.sectionsName === 'advisorsAlphaCol2Sections'){
                        let advisorSectionName = column.sectionsName.toLowerCase()
                        sectionData = response.silossectionscontent[column.sectionsName][advisorSectionName].advisorsAlphaColSections;
                    }else {
                        sectionData = response.silossectionscontent[column.sectionsName];
                    }
                    section = Object.values(sectionData);
                    if (!Array.isArray(section)) {
                        section = [section];
                    }
                        section.forEach(menu => {
                            if (!Array.isArray(menu.menuItems)) {
                                menu.menuItems = Object.values(menu.menuItems);
                            }
                        });
                    response.silossectionscontent[column.sectionsName] = section;
                });
            }
        });
        return navContent;
    } catch (error) {
        logger.log('globalnavigation-webapp: Error in contentService.js while returning global nav content ' + JSON.stringify(error));
        console.error(error)
    }
}
export default navData;
